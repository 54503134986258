import { Component, OnInit,ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { form_data } from './type';
import { MatTableDataSource} from '@angular/material/table';
import Swal from 'sweetalert2'
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-spam-ham',
  templateUrl: './spam-ham.component.html',
  styleUrls: ['./spam-ham.component.css']
})
export class SpamHamComponent implements OnInit {
  viewMode = 'tab1';
  
  public form_data:form_data = new form_data();

 
  constructor(private httpClient: HttpClient,private spinner: NgxSpinnerService) { }


  files: File[] = [];
  value_counts;
  data;
  displayedColumns;
  dataSource = new MatTableDataSource();
  showResult = false;
  length;
  chart_data =[] ;
  view: any[] = [700, 400];

  // options
  showLegend = true;

  // colorScheme = {
  //   domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  // };

  // pie
  showLabels = true;
  explodeSlices = false;
  doughnut = false;
  // // options
  // showLegend = true;

  colorScheme = {
    domain: ['#5AA454','#C7B42C','#A10A28', '#C7B42C', '#AAAAAA']
  };

  // // pie
  // showLabels = true;
  // explodeSlices = false;
  // doughnut = false;



  ngOnInit() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
  }
  

  //Spam batch prediction
  batch_predict(event) {
    let formData: FormData = new FormData();
  
    this.files.push(...event.addedFiles);
  
    formData.append('file', this.files[0]);
    this.spinner.show();
    this.httpClient.post(environment.apiUrl+'v1/ml/spam_batch', formData).subscribe(
      res => {
        this.spinner.hide()
        Swal.fire("Prediction Done",'Please see the results below !','success')
        
        this.data = res['body']['result']
        this.value_counts =res['body']['value_counts']
        
        let keys = Object.keys(res['body']['value_counts'])

        for(let i=0;i<keys.length;i++){
          this.chart_data.push({
            "name":keys[i],
            "value":res['body']['value_counts'][keys[i]]
          })
        }
       
       
        this.displayedColumns = Object.keys(res['body']['result'][0]);
       
        this.length = res['body']['result'].length;
        this.showResult = true;
        
        let exportData = res['body']['result'].slice(0,7)
        console.log("Export data ",exportData)
        this.dataSource = new MatTableDataSource(exportData)

        console.log("Data",this.data)
      },
      err => {
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
      }, 2000);
        Swal.fire("Error",'Something went wrong !','error')
        
        console.log(err)
      }
    )

  }
  export(){
    this.spinner.show();
    new Angular5Csv(this.data,'Ham_Spam_Prediction',{showLabels:true,headers:this.displayedColumns})
    this.spinner.hide();
  }
  predict_spam(event){
    var data = {
      "email":this.form_data.email,
    }
    this.spinner.show();
    this.httpClient.post(environment.apiUrl +'v1/ml/spam',data).subscribe(
      res => {
        this.spinner.hide()
        if(res['body']=='ham'){
          Swal.fire(res['body'],'Ham or SPAM ','success')
        }
        else{
          Swal.fire(res['body'],'Ham or SPAM ','error')
        }
        
      },
      err => {
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
      }, 2000);
        console.log(err)
        Swal.fire("Error",'Something went wrong !','error')
      })
  }

}
