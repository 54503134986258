import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { form_data } from './type';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-iris',
  templateUrl: './iris.component.html',
  styleUrls: ['./iris.component.css']
})
export class IrisComponent implements OnInit {

  public form_data:form_data = new form_data();

  constructor(private httpClient: HttpClient,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
  }

  predict_iris(event){
    var data = {
      "sepal_length":this.form_data.sepal_length,
      "sepal_width":this.form_data.sepal_width,
      "petal_length":this.form_data.petal_length,
      "petal_width":this.form_data.petal_width
    }
    this.spinner.show();
    this.httpClient.post(environment.apiUrl +'v1/ml/iris',data).subscribe(
      res => {
        this.spinner.hide()
          Swal.fire("Iris Prediction",res['body'],'success')
        
      },
      err => {
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
      }, 2000);
        console.log(err)
        Swal.fire("Error",'Something went wrong !','error')
      })
  }

}
