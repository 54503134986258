import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { form_data } from './type';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2'
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-auto-mpg',
  templateUrl: './auto-mpg.component.html',
  styleUrls: ['./auto-mpg.component.css']
})
export class AutoMpgComponent implements OnInit {

  public form_data:form_data = new form_data();

  public selectedCylinder : any;
  public selectedOrigin:any;

  constructor(private httpClient: HttpClient,private spinner: NgxSpinnerService) { }

  ngOnInit() {

    

  }


  predict_mpg(event){
    var data = {
      "cylinders": this.selectedCylinder,
      "displacement": this.form_data.displacement,
      "horepower": this.form_data.horsepower,
      "weight": this.form_data.weight,
      "acceleration": this.form_data.acceleration,
      "model_year": this.form_data.model_year,
      "Origin": this.selectedOrigin
    }
    console.log(data)
    this.spinner.show();
    this.httpClient.post(environment.apiUrl +'/v1/ml/mpg',data).subscribe(
      res => {
        this.spinner.hide()
          Swal.fire("MPG Prediction" + "",res['body'],'success')
        
      },
      err => {
        setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinner.hide();
      }, 2000);
        console.log(err)
        Swal.fire("Error",'Something went wrong !','error')
      })
  }

}
