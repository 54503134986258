import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  public currentDate = new Date();
  public experience = 0
  public startDate = new Date('2018-05-10');
  public difference =  new Date(this.startDate.getTime() - this.currentDate.getTime());
  public years  =1970-this.difference.getUTCFullYear()
  // public diff =  new Date(currentDate - startDate);
  // public years = Math.abs(this.diff.getUTCFullYear()-1970)

  constructor() {
    // this.currentDate = this.difference.getUTCFullYear()-1970
    this.experience = parseInt( this.years.toString())
    console.log(this.experience)
   
   }

  ngOnInit() {
  }

}
