import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule,ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BannerComponent } from './banner/banner.component';
import { NavbarComponent } from './navbar/navbar.component';
import { AboutComponent } from './about/about.component';
import { WorkEducationComponent } from './work-education/work-education.component';
import { ProjectsComponent } from './projects/projects.component';
import { SkillsComponent } from './skills/skills.component';
import { FooterComponent } from './footer/footer.component';
import { MNISTComponent } from './mnist/mnist.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { AutoMpgComponent } from './auto-mpg/auto-mpg.component';
import { ContactComponent } from './contact/contact.component';
import { TextClassificationComponent } from './text-classification/text-classification.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { WorkComponent } from './work/work.component';
import { EducationComponent } from './education/education.component';
import { SpamHamComponent } from './spam-ham/spam-ham.component';
import {NgxDropzoneModule} from 'ngx-dropzone';
import { RecaptchaModule } from 'ng-recaptcha';
import { MatTableModule} from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { IrisComponent } from './iris/iris.component';
import { HousePriceComponent } from './house-price/house-price.component';
import { UniversityAdmiComponent } from './university-admi/university-admi.component';
import { FoodVisionComponent } from './food-vision/food-vision.component';
@NgModule({
  declarations: [
    AppComponent,
    BannerComponent,
    NavbarComponent,
    AboutComponent,
    WorkEducationComponent,
    ProjectsComponent,
    SkillsComponent,
    FooterComponent,
    MNISTComponent,
    LandingpageComponent,
    AutoMpgComponent,
    ContactComponent,
    TextClassificationComponent,
    WorkComponent,
    EducationComponent,
    SpamHamComponent,
    IrisComponent,
    HousePriceComponent,
    UniversityAdmiComponent,
    FoodVisionComponent
  ],
  imports: [
    NgxChartsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    RecaptchaModule,
    ScrollToModule.forRoot(),
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    FormsModule,
    MDBBootstrapModule.forRoot(),
    NgxSpinnerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
