import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BannerComponent} from './banner/banner.component';
import { MNISTComponent } from './mnist/mnist.component';
import { IrisComponent } from './iris/iris.component'
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import {LandingpageComponent} from './landingpage/landingpage.component';
import { AutoMpgComponent } from './auto-mpg/auto-mpg.component';
import {SpamHamComponent } from './spam-ham/spam-ham.component'
import { TextClassificationComponent} from './text-classification/text-classification.component'
import { HousePriceComponent } from './house-price/house-price.component';
import { UniversityAdmiComponent } from './university-admi/university-admi.component';
import { FoodVisionComponent } from './food-vision/food-vision.component';

const routes: Routes = [

  {path:'',component:LandingpageComponent},
  {path:'MNIST',component:MNISTComponent},
  {path:'Auto_Mpg',component:AutoMpgComponent},
  {path:'house_price',component:HousePriceComponent},
  {path:'Text_Class',component:TextClassificationComponent},
  {path:'spam_detect',component:SpamHamComponent},
  {path: 'iris',component:IrisComponent},
  {path:'univ_admission',component:UniversityAdmiComponent},
  {path:'food_vision',component:FoodVisionComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes),ScrollToModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
