import { Component, OnInit } from '@angular/core';
import Typed from 'typed.js';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var name = {
      strings: ["Hi I'm Geoffrey"],
      typeSpeed: 30,
      fadeOut: true,
      showCursor: false,
    }
    var typed = new Typed('.name',name);

    var title = {
      strings: ["ML Architect and A Passionate Programmer"],
      startDelay: 1500,
      typeSpeed: 40,
      fadeOut:true,
      showCursor:false,
      smartBackspace:true,
    } 
    
    var typed2 = new Typed('.title',title)
    // var title1 = new Typed('.title', title)
  }

}
