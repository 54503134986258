import { Component, OnInit } from '@angular/core';
import { ContactFormData } from './type';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  public ContactFormData: ContactFormData = new ContactFormData();

  constructor(private httpClient: HttpClient, private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  send_email(form: NgForm) {
    if (form.invalid) {
      Swal.fire("Error", "Please fill in all the required fields.", "error");
      return;
    }

    console.log(JSON.stringify(this.ContactFormData));
    var form_data = {
      'Email': this.ContactFormData.email,
      'Name': this.ContactFormData.name,
      'MobileNumber': this.ContactFormData.mobileNumber,
      'Message': this.ContactFormData.message
    };
    var data = JSON.parse(JSON.stringify(this.ContactFormData));
    
    console.log(typeof(data));
    console.log(data);
    
    // Show the spinner
    this.spinner.show();
    
    this.httpClient.post(environment.apiUrl + '/v1/misc/send_email', { 'data': data }).subscribe(
      res => {
        console.log(res);
        this.spinner.hide();
        Swal.fire("Email Sent successfully", 'You will receive a response shortly!', 'success');
      },
      err => {
        console.log(err);
        this.spinner.hide();
        Swal.fire("Error", 'Something went wrong! Try again later', 'error');
      }
    );
  }
}
