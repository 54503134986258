import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  views = [true, false, false, false];
  constructor() { }

  ngOnInit() {
  }

  changeTab(ind) {
    // tslint:disable-next-line:forin
    for (const val in this.views) {
      this.views[val] = false;
    }
    this.views[ind] = true;
  }

}
