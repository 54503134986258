
export class form_data {
    email: string;

    constructor() {
        this.email = this.getRandomEmail();
    }

    emailList = [
        "Please call our customer service representative on 0800 169 6031 between 10am-9pm as you have WON a guaranteed £1000 cash or £5000 prize!",
        "Good Morning, Have a nice day",
        "Thank you for your response, Have a good rest of your day",
        "Free entry in 2 a wkly comp to win FA Cup final tkts 21st May 2005. Text FA to 87121 to receive entry question (std txt rate) T&C's apply"
    ];

    getRandomEmail(): string {
        const randomIndex = Math.floor(Math.random() * this.emailList.length);
        return this.emailList[randomIndex];
    }
}
