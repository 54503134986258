export class form_data{
    cylinders = [3,4,5,6,8];
    displacement:318.0;
    horsepower:150;
    weight:3436;
    acceleration:11.0;	
    model_year:70;
    origins = ['USA','Japan','Europe'];
    


}