import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-house-price',
  templateUrl: './house-price.component.html',
  styleUrls: ['./house-price.component.css']
})
export class HousePriceComponent implements OnInit {

  public form_data: any = {
    
    bedrooms: 3,
    bathrooms: 2,
    sqft_living: 2000,
    sqft_lot: 7500,
    floors: 1,
    waterfront: 0,
    view: 0,
    condition: 3,
    grade: 7,
    sqft_above: 1500,
    sqft_basement: 500,
    yr_built: 1970,
    yr_renovated: 2003,
    zipcode: 98117,
    lat: 47.6801,
    long: -122.357,
    sqft_living15: 1940	,
    sqft_lot15: 5000
  };

  constructor(private httpClient: HttpClient, private spinner: NgxSpinnerService) { }

  ngOnInit() { }

  predict_house_price(event) {
    event.preventDefault();

    const data = {
      bedrooms: this.form_data.bedrooms,
      bathrooms: this.form_data.bathrooms,
      sqft_living: this.form_data.sqft_living,
      sqft_lot: this.form_data.sqft_lot,
      floors: this.form_data.floors,
      waterfront: this.form_data.waterfront,
      view: this.form_data.view,
      condition: this.form_data.condition,
      grade: this.form_data.grade,
      sqft_above: this.form_data.sqft_above,
      sqft_basement: this.form_data.sqft_basement,
      yr_built: this.form_data.yr_built,
      yr_renovated: this.form_data.yr_renovated,
      zipcode: this.form_data.zipcode,
      lat: this.form_data.lat,
      long: this.form_data.long,
      sqft_living15: this.form_data.sqft_living15,
      sqft_lot15: this.form_data.sqft_lot15
    };

    console.log(data);
    this.spinner.show();
    this.httpClient.post(environment.apiUrl + '/v1/ml/house_price', data).subscribe(
      res => {
        this.spinner.hide();
        Swal.fire('Prediction', res['body'], 'info');
      },
      err => {
        setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        console.log(err);
        Swal.fire('Error', 'Something went wrong!', 'error');
      }
    );
  }
}
