import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mnist',
  templateUrl: './mnist.component.html',
  styleUrls: ['./mnist.component.css']
})
export class MNISTComponent implements OnInit {
  title = 'Image Classification of MNIST  DataSet';
  constructor() { }

  ngOnInit() {
  }

}
