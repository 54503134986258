import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-food-vision',
  templateUrl: './food-vision.component.html',
  styleUrls: ['./food-vision.component.css']
})
export class FoodVisionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
