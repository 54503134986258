import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-university-admi',
  templateUrl: './university-admi.component.html',
  styleUrls: ['./university-admi.component.css']
})
export class UniversityAdmiComponent implements OnInit {

  public form_data: any = {
    gre_score: 300,
    toefl_score: 100,
    university_rating: 3,
    sop: 3.5,
    lor: 3.5,
    cgpa: 8.0,
    research: 1
  };

  constructor(private httpClient: HttpClient, private spinner: NgxSpinnerService) { }

  ngOnInit() { }

  predict_admission(event) {
    event.preventDefault();

    var data = {
      gre_score: this.form_data.gre_score,
      toefl_score: this.form_data.toefl_score,
      university_rating: this.form_data.university_rating,
      sop: this.form_data.sop,
      lor: this.form_data.lor,
      cgpa: this.form_data.cgpa,
      research: this.form_data.research
    };

    console.log(data);
    this.spinner.show();
    this.httpClient.post(environment.apiUrl + 'v1/ml/admission', data).subscribe(
      res => {
        this.spinner.hide();

        this.spinner.hide()
        if(res['body']['prediction']>50){
          Swal.fire('Admission Prediction', res['body']['msg'], 'success');
        }
        else{
          Swal.fire('Admission Prediction', res['body']['msg'], 'error');
        }

       
      },
      err => {
        setTimeout(() => {
          this.spinner.hide();
        }, 2000);
        console.log(err);
        Swal.fire('Error', 'Something went wrong!', 'error');
      }
    );
  }
}
